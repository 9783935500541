import React, { Component } from 'react';
import { arrayOf, func, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';

import config from '../../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatCurrencyMajorUnit } from '../../../util/currency';

import IconPlus from '../IconPlus/IconPlus';
import PriceFilterForm from '../PriceFilterForm/PriceFilterForm';

import css from './PriceFilterPlain.module.css';
import { IconCollection, Modal } from '../../../components';

const RADIX = 10;

const getPriceQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'price';
};

// Parse value, which should look like "0,1000"
const parse = priceRange => {
  const [minPrice, maxPrice] = !!priceRange
    ? priceRange.split(',').map(v => Number.parseInt(v, RADIX))
    : [];
  // Note: we compare to null, because 0 as minPrice is falsy in comparisons.
  return !!priceRange && minPrice != null && maxPrice != null ? { minPrice, maxPrice } : null;
};

// Format value, which should look like { minPrice, maxPrice }
const format = (range, queryParamName) => {
  const { minPrice, maxPrice } = range || {};
  // Note: we compare to null, because 0 as minPrice is falsy in comparisons.
  const value = minPrice != null && maxPrice != null ? `${minPrice},${maxPrice}` : null;
  return { [queryParamName]: value };
};

class PriceFilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }
  handleBlur() {
    this.setState({ isOpen: false });
    this.props.onOpenFilterModal(false);
  }
  handleChange(values) {
    const { onSubmit, queryParamNames, handleFilterSubmit, isManagePage, isProfile } = this.props;
    const priceQueryParamName = getPriceQueryParamName(queryParamNames);
    onSubmit(format(values, priceQueryParamName));
    if (isManagePage || isProfile) {
      handleFilterSubmit(format(values, priceQueryParamName));
    }
  }

  handleClear() {
    const { onSubmit, queryParamNames } = this.props;
    const priceQueryParamName = getPriceQueryParamName(queryParamNames);
    onSubmit(format(null, priceQueryParamName));
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    this.props.onOpenFilterModal(true);
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      label,
      queryParamNames,
      initialValues,
      min,
      max,
      step,
      intl,
      currencyConfig,
      plainClassName,
      onManageDisableScrolling,
      isDesktop,
      handleFilterSubmit,
      isManagePage,
      isProfile,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const priceQueryParam = getPriceQueryParamName(queryParamNames);
    const initialPrice = initialValues ? parse(initialValues[priceQueryParam]) : {};
    const { minPrice, maxPrice } = initialPrice || {};

    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(minPrice) && hasValue(maxPrice);

    const labelSelection = hasInitialValues
      ? intl.formatMessage(
          { id: 'PriceFilter.labelSelectedPlain' },
          {
            minPrice: formatCurrencyMajorUnit(intl, currencyConfig.currency, minPrice),
            maxPrice: formatCurrencyMajorUnit(intl, currencyConfig.currency, maxPrice),
          }
        )
      : null;
    return (
      <div className={classes}>
        {/* <OutsideClickHandler onOutsideClick={this.handleBlur}> */}
        <div className={css.filterHeader}>
          <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={css.labelWrapper}>
                <span className={css.label}>
                  {label}
                  {labelSelection ? (
                    <>
                      <span>{': '}</span>
                      <span className={css.labelSelected}>{labelSelection}</span>
                    </>
                  ) : null}
                </span>
              </span>
            </span>
            <span
              className={classNames(css.categoryIconMobile, this.state.isOpen && css.arrowDown)}
            >
              <IconCollection icon="IconArrowDown" />
            </span>
          </button>
        </div>
        {isDesktop ? (
          <Modal
            isOpen={this.state.isOpen}
            onClose={() => [this.toggleIsOpen(), this.props.onOpenFilterModal(false)]}
            onManageDisableScrolling={onManageDisableScrolling}
            className={css.modalSearchFilter}
            id="PriceFilterPlain"
          >
            {/* <div
          className={classNames(plainClassName, css.plain, {
            [css.isOpen]: this.state.isOpen,
          })}
        > */}
            <PriceFilterForm
              id={id}
              initialValues={hasInitialValues ? initialPrice : { minPrice: min, maxPrice: max }}
              onChange={this.handleChange}
              intl={intl}
              contentRef={node => {
                this.filterContent = node;
              }}
              min={min}
              max={max}
              step={step}
              liveEdit
              isOpen={this.state.isOpen}
              isInSideBar
              style={{ minWidth: '160px' }}
              onClose={() => [this.toggleIsOpen(), this.props.onOpenFilterModal(false)]}
              queryParamNames={queryParamNames}
              handleFilterSubmit={handleFilterSubmit}
              isManagePage={isManagePage}
              isProfile={isProfile}
            >
              <button className={css.clearButton} onClick={this.handleClear}>
                <FormattedMessage id={'PriceFilter.clear'} />
              </button>
            </PriceFilterForm>
            {/* </div> */}
          </Modal>
        ) : (
          <>
            {this.state.isOpen ? (
              <PriceFilterForm
                id={id}
                initialValues={hasInitialValues ? initialPrice : { minPrice: min, maxPrice: max }}
                onChange={this.handleChange}
                intl={intl}
                contentRef={node => {
                  this.filterContent = node;
                }}
                min={min}
                max={max}
                step={step}
                liveEdit
                isOpen={this.state.isOpen}
                isInSideBar
                style={{ minWidth: '160px' }}
                onClose={() => [this.toggleIsOpen(), this.props.onOpenFilterModal(false)]}
                queryParamNames={queryParamNames}
                handleFilterSubmit={handleFilterSubmit}
                isManagePage={isManagePage}
                isProfile={isProfile}
              >
                <button className={css.clearButton} onClick={this.handleClear}>
                  <FormattedMessage id={'PriceFilter.clear'} />
                </button>
              </PriceFilterForm>
            ) : null}
          </>
        )}
        {/* </OutsideClickHandler> */}
      </div>
    );
  }
}

PriceFilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  plainClassName: null,
  step: number,
  currencyConfig: config.currencyConfig,
};

PriceFilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    price: string,
  }),
  plainClassName: string,
  currencyConfig: propTypes.currencyConfig,

  // form injectIntl
  intl: intlShape.isRequired,
};

const PriceFilterPlain = injectIntl(PriceFilterPlainComponent);

export default PriceFilterPlain;
