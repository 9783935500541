import React, { Component } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';

import FilterForm from '../FilterForm/FilterForm';

import css from './FilterPlain.module.css';

import { IconCollection, Modal } from '../../../components';
import { subCategory } from '../../../config/marketplace-custom-config';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';
import { getPageRoute } from '../../../util/dataExtractor';
import { MEN_GENDER, WOMEN_GENDER } from '../../../util/types';

export const Filters = props => {
  const {
    onModalHandle,
    label,
    handleCategoryClick,
    isProfile,
    params,
    history,
    validQueryParams,
    contentPlacementOffset,
    initialValues,
    keepDirtyOnReinitialize,
    handleOnChange,
    toggleIsOpen,
    children,
    id,
    options,
    selectedCategory,
    handleClear,
    isSingleModal,
    handleSubCategory,
    isFavouritePage,
    handleBrandClick,
  } = props;

  const hasValidGender = validQueryParams.pub_gender === MEN_GENDER || validQueryParams.pub_gender === WOMEN_GENDER;
  const hasValidListingStore = !!validQueryParams.pub_listingStore;

  const filteredCategory = options?.filter(e => {
    const matchesGender = hasValidGender ? e.genderKey?.includes(validQueryParams.pub_gender) : true;
    const matchesListingStore = hasValidListingStore ? e.parentKey?.includes(validQueryParams.pub_listingStore) : true;

    return matchesGender && matchesListingStore;
  }) || options;


  const filteredSubCategory =
  validQueryParams.pub_gender === MEN_GENDER ||  validQueryParams.pub_gender === WOMEN_GENDER ?   subCategory?.length &&
        subCategory.filter(
          e =>  e.genderKey?.includes(validQueryParams.pub_gender)) : subCategory;
  return (
    <div className={css.filterPlainMain}>
      <FilterForm
        id={`${id}.form`}
        onModalHandle={onModalHandle}
        label={label}
        isProfile={isProfile}
        params={params}
        history={history}
        validQueryParams={validQueryParams}
        liveEdit
        contentPlacementOffset={contentPlacementOffset}
        onChange={handleOnChange}
        initialValues={initialValues}
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        isFavouritePage={isFavouritePage}
        handleBrandClick={handleBrandClick}
        handleClear={handleClear}
      >
        <div className={css.dropDownHeader}>
          <span className={css.dropdownLabel}>Choose {label}</span>{' '}
          <span
            className={css.dropDownReset}
            onClick={() => {
              handleClear(), toggleIsOpen();
            }}
          >
            <FormattedMessage id="PriceFilterForm.reset" />
          </span>
        </div>
        <div className={classNames(isSingleModal && css.filterPlainDiv)}>
          <p
            className={css.item}
            onClick={() => {
              handleClear(), toggleIsOpen();
            }}
          >
            {' '}
            <FormattedMessage id="LandingPage.allText" />
          </p>
          {validQueryParams.pub_gender ? validQueryParams.pub_gender : null}
          {label === 'Sub Category' || label === 'Category' ? (
            <div>
              <div className={css.customselect}>
                <div className={css.customList}>
                  {filteredCategory.map(category => (
                    <div
                      key={category.key}
                      className={classNames(
                        css.category,
                        validQueryParams?.pub_category?.split(',').includes(category.key)
                          ? css.active
                          : ''
                      )}
                    >
                      <span
                        onClick={() => handleCategoryClick(category.key)}
                        className={
                          validQueryParams?.pub_category?.split(',').includes(category.key)
                            ? css.text1
                            : null
                        }
                      >
                        {validQueryParams?.pub_category?.split(',').includes(category.key) ? (
                          <IconCollection icon="blueIconCheck" />
                        ) : null}
                        {category.label}
                      </span>
                      {selectedCategory === category.key && (
                        <div className={css.subcategories}>
                          {filteredSubCategory
                            .filter(e => e.parentKey === selectedCategory)
                            .map(sub => (
                              <div
                                key={sub.key}
                                className={classNames(css.subcategory)}
                                onClick={() => {
                                  handleSubCategory(sub.key);
                                }}
                              >
                                {validQueryParams.pub_subCategory === sub.key && (
                                  <span className={css.subCategorycategoryIcon}>
                                    <IconCollection icon="blueIconCheck" />
                                  </span>
                                )}{' '}
                                <span
                                  className={
                                    validQueryParams.pub_subCategory === sub.key ? css.text : null
                                  }
                                >
                                  {sub.label}
                                </span>
                              </div>
                            ))}
                        </div>
                      )}
                      <span className={css.categoryIcon}>
                        <IconCollection icon="IconArrowDown" />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <>{children}</>
          )}
        </div>
      </FilterForm>
      <button className={css.clearButton} onClick={handleClear}>
        <FormattedMessage id={'FilterPlain.clear'} />
      </button>
    </div>
  );
};
class FilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, selectedCategory: [], isFieldShow: true, subCategoryCount: [] };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleBlur() {
    this.setState({ isOpen: false });
    this.props.onOpenFilterModal(false);
  }
  handleChange(values) {
    const { onSubmit, label } = this.props;
    onSubmit(values);
    this.setState({ isOpen: false });
    this.props.onOpenFilterModal(false);
  }

  handleClear() {
    const { onSubmit, onClear } = this.props;

    if (onClear) {
      onClear();
      this.toggleIsOpen();
    }

    onSubmit(null);
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    this.props.onOpenFilterModal(true);
  }

  render() {
    const {
      rootClassName,
      className,
      plainClassName,
      id,
      label,
      labelSelection,
      labelSelectionSeparator,
      isSelected,
      children,
      initialValues,
      keepDirtyOnReinitialize,
      contentPlacementOffset,
      options,
      validQueryParams,
      history,
      onManageDisableScrolling,
      onSubmit,
      isManagePage,
      isProfile,
      params,
      onOpenFilterModal,
      isDesktop,
      isMoreFilterOpen,
      isFavouritePage,
    } = this.props;

    const onModalHandle = () => {
      this.setState({ isOpen: false });
      onOpenFilterModal(false);
    };

    const classes = classNames(rootClassName || css.root, className);
    const handleCategoryClick = category => {
      if (this.state.selectedCategory === category) {
        this.setState({ selectedCategory: null });
      } else {
        this.setState({ selectedCategory: category });
        onSubmit({
          category: validQueryParams.pub_category
            ? [validQueryParams.pub_category, category]
            : [category],
        });
      }
    };

    const handleBrandClick = brand => {
      onSubmit({
        brand: validQueryParams.pub_brand ? [validQueryParams.pub_brand, brand] : [brand],
      });
    };

    const handleSubCategory = value => {
      this.setState(prevState => ({
        subCategoryCount: [...prevState.subCategoryCount, value],
      }));
      const search = {
        ...validQueryParams,
        pub_subCategory: [value],
      };
      const routes = routeConfiguration();
      const pageRoute = getPageRoute(params?.state, isProfile);
      history.push(
        createResourceLocatorString(
          isFavouritePage ? 'FavouriteListingsPage' : pageRoute,
          routes,
          isProfile || isManagePage
            ? {
                state: isManagePage ? params?.state : null,
                id: isProfile ? params?.id : null,
              }
            : {},
          search
        )
      );
      this.toggleIsOpen();
    };
    return (
      <div className={classes}>
        {/* <OutsideClickHandler onOutsideClick={this.handleBlur}> */}
        <div className={css.filterHeader}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={css.labelWrapper}>
                <span className={css.label}>
                  {label}
                  {labelSelection && labelSelectionSeparator ? labelSelectionSeparator : null}
                  {labelSelection ? (
                    <span className={css.labelSelected}>: {labelSelection}</span>
                  ) : (
                    <span className={css.labelSelected}>
                      : <FormattedMessage id="LandingPage.allText" />
                    </span>
                  )}
                </span>
              </span>
            </span>
            <span
              className={classNames(css.categoryIconMobile, this.state.isOpen && css.arrowDown)}
            >
              <IconCollection icon="IconArrowDown" />
            </span>
          </button>
        </div>
        {isDesktop ? (
          <>
            {isMoreFilterOpen ? (
              <div>
                <>
                  {this.state.isOpen ? (
                    <div
                      className={classNames(
                        css.searchFiltersMobileList,
                        isManagePage && css.filterMangePage
                      )}
                    >
                      <Filters
                        onModalHandle={onModalHandle}
                        label={label}
                        isProfile={isProfile}
                        params={params}
                        history={history}
                        validQueryParams={validQueryParams}
                        contentPlacementOffset={contentPlacementOffset}
                        initialValues={initialValues}
                        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
                        handleOnChange={this.handleChange}
                        handleCategoryClick={handleCategoryClick}
                        toggleIsOpen={this.toggleIsOpen}
                        children={children}
                        id={id}
                        options={options}
                        selectedCategory={this.state.selectedCategory}
                        handleClear={this.handleClear}
                        handleSubCategory={handleSubCategory}
                        isFavouritePage={isFavouritePage}
                        handleBrandClick={handleBrandClick}
                      />
                    </div>
                  ) : null}
                </>
              </div>
            ) : (
              <Modal
                isOpen={this.state.isOpen}
                onClose={() => [this.handleBlur(), this.toggleIsOpen]}
                onManageDisableScrolling={onManageDisableScrolling}
                className={css.modalCategory}
                id="FilterPlain"
              >
                <Filters
                  onModalHandle={onModalHandle}
                  label={label}
                  isProfile={isProfile}
                  params={params}
                  history={history}
                  validQueryParams={validQueryParams}
                  contentPlacementOffset={contentPlacementOffset}
                  initialValues={initialValues}
                  keepDirtyOnReinitialize={keepDirtyOnReinitialize}
                  handleOnChange={this.handleChange}
                  handleCategoryClick={handleCategoryClick}
                  toggleIsOpen={this.toggleIsOpen}
                  children={children}
                  id={id}
                  options={options}
                  selectedCategory={this.state.selectedCategory}
                  handleClear={this.handleClear}
                  isSingleModal={true}
                  handleSubCategory={handleSubCategory}
                  isFavouritePage={isFavouritePage}
                  handleBrandClick={handleBrandClick}
                />
                {/* </div> */}
              </Modal>
            )}
          </>
        ) : (
          <>
            {this.state.isOpen ? (
              <>
                <Filters
                  onModalHandle={onModalHandle}
                  label={label}
                  isProfile={isProfile}
                  params={params}
                  history={history}
                  validQueryParams={validQueryParams}
                  contentPlacementOffset={contentPlacementOffset}
                  initialValues={initialValues}
                  keepDirtyOnReinitialize={keepDirtyOnReinitialize}
                  handleOnChange={this.handleChange}
                  handleCategoryClick={handleCategoryClick}
                  toggleIsOpen={this.toggleIsOpen}
                  children={children}
                  id={id}
                  options={options}
                  selectedCategory={this.state.selectedCategory}
                  handleClear={this.handleClear}
                  handleSubCategory={handleSubCategory}
                  isFavouritePage={isFavouritePage}
                  handleBrandClick={handleBrandClick}
                />
              </>
            ) : null}
          </>
        )}
        {/* </OutsideClickHandler> */}
      </div>
    );
  }
}

FilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  plainClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  labelSelection: null,
  labelSelectionSeparator: null,
};

FilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  plainClassName: string,
  id: string.isRequired,
  onSubmit: func.isRequired,
  label: node.isRequired,
  labelSelection: node,
  labelSelectionSeparator: node,
  isSelected: bool.isRequired,
  children: node.isRequired,
  initialValues: object,
  keepDirtyOnReinitialize: bool,

  // form injectIntl
  intl: intlShape.isRequired,
};

const FilterPlain = injectIntl(FilterPlainComponent);

export default FilterPlain;
