import React, { useCallback, useState } from 'react';
import { bool, func, node, object } from 'prop-types';
import classNames from 'classnames';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';

import { Form, IconCollection } from '../../../components';

import css from './FilterForm.module.css';
import { searchBrand } from '../../../util/api';
import { getRoute } from '../../../util/dataExtractor';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';

const FilterFormComponent = props => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('FilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'FilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = formState => {
    if (formState.dirty) {
      onChange(formState.values);
    }
  };

  const formCallbacks = liveEdit ? { onSubmit: () => null } : { onSubmit, onCancel, onClear };
  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          id,
          form,
          handleSubmit,
          onClear,
          onCancel,
          style,
          paddingClasses,
          label,
          intl,
          children,
          values,
          history,
          isProfile,
          params,
          validQueryParams,
          onModalHandle,
          isFavouritePage,
          handleBrandClick,
          handleClear,
        } = formRenderProps;

        const [isUlVisible, setIsUlVisible] = useState(false);

        const noResultFound = intl.formatMessage({
          id: 'EditListingDetailsForm.noResultFound',
        });

        const handleSearchChange = async (value, callback) => {
          try {
            const response = await searchBrand(value);
            form.change('searchResults', response);
            setIsUlVisible(true);
          } catch (error) {}
        };
        const debounce = (func, delay) => {
          let timerId;
          return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
              func(...args);
            }, delay);
          };
        };
        const debouncedSearch = useCallback(debounce(handleSearchChange, 300), []);

        const handleLiClick = brandName => {
          form.batch(() => {
            form.change('searchResults', brandName);
            form.change('brand', brandName);
          });
          handleBrandClick(brandName);
          onModalHandle();
          setIsUlVisible(false); // Hide the <ul> when <li> is clicked
        };

        const handleClearClick = () => {
          handleClear();
          form.batch(() => {
            form.change('searchResults', null);
          });
        };

        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          handleClear();
        };

        const clear = intl.formatMessage({ id: 'FilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'FilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'FilterForm.submit' });
        const brandPlaceholder = intl.formatMessage({ id: 'FilterForm.searchBrand' });

        const classes = classNames(css.root);

        return (
          <Form
            id={id}
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            style={{ ...style }}
          >
            {label === 'Brand' ? (
              <div className={css.brandInput}>
                <Field name="brand">
                  {({ input }) => (
                    <div className={css.searchContainer}>
                      <IconCollection icon="SearchIcon" />
                      <input
                        {...input}
                        type="text"
                        autoComplete="off"
                        placeholder={brandPlaceholder}
                        onChange={e => {
                          input.onChange(e);
                          debouncedSearch(e.target.value);
                        }}
                      />
                      {/* {values?.brand && (
                        <button
                          type="button"
                          className={css.clearSearchButton}
                          onClick={()=>handleClearClick()}
                        >
                          &#10005; 
                        </button>
                      )} */}
                    </div>
                  )}
                </Field>
                {isUlVisible && values?.searchResults?.length ? (
                  <Field name="searchResults">
                    {({ input }) => (
                      <div className={css.brandDropDown}>
                        <ul className={css.searchResults_show}>
                          {Array.isArray(input?.value) ? (
                            input.value.map(brand => (
                              <li key={brand._id} onClick={() => handleLiClick(brand?.name)}>
                                {brand.name}
                              </li>
                            ))
                          ) : (
                            <>
                              <li>{noResultFound}</li>
                              <button
                                type="button"
                                className={css.clearSearchButton}
                                onClick={() => setIsUlVisible(false)}
                              >
                                &#10005; {/* Cross sign */}
                              </button>
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                  </Field>
                ) : null}
              </div>
            ) : (
              <>
                <div className={classNames(paddingClasses || css.contentWrapper)}>{children}</div>

                {liveEdit ? (
                  <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
                ) : (
                  <div className={css.buttonsWrapper}>
                    <button className={css.clearButton} type="button" onClick={onClear}>
                      {clear}
                    </button>
                    <button className={css.cancelButton} type="button" onClick={handleCancel}>
                      {cancel}
                    </button>
                    <button className={css.submitButton} type="submit">
                      {submit}
                    </button>
                  </div>
                )}
              </>
            )}
          </Form>
        );
      }}
    />
  );
};

FilterFormComponent.defaultProps = {
  liveEdit: false,
  style: null,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

FilterFormComponent.propTypes = {
  liveEdit: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  style: object,
  children: node.isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

const FilterForm = injectIntl(FilterFormComponent);

export default FilterForm;
